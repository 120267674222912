import { isDefined } from "@clipboard-health/util-ts";
import { calculateGeoDistanceInMiles, convertToGeoLocation } from "@src/appV2/Location";
import { type Worker } from "@src/appV2/Worker/api/types";

import { type Facility } from "../types";

interface CalculateDistanceToFacilityInMilesResult {
  distanceInMiles: number;
  isAproximatedDistance: boolean;
}

export function calculateDistanceToFacilityInMiles(
  agent: Worker,
  facility: Facility
): CalculateDistanceToFacilityInMilesResult {
  if (isDefined(facility.distance)) {
    const isAproximatedDistance = facility.isApproxDistance ?? false;
    const distanceInMiles = Number.parseFloat(facility.distance.toFixed(2));

    return {
      distanceInMiles: isAproximatedDistance
        ? Math.round(2 * distanceInMiles) / 2 // Round to nearest half
        : distanceInMiles,
      isAproximatedDistance,
    };
  }

  const geoDistanceInMiles = calculateGeoDistanceInMiles(
    convertToGeoLocation(agent.geoLocation?.coordinates ?? [0, 0]),
    convertToGeoLocation(facility.geoLocation.coordinates)
  );

  return {
    distanceInMiles: Math.round(2 * geoDistanceInMiles) / 2,
    isAproximatedDistance: true,
  };
}
