import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { z } from "zod";

import { facilityPhotoSchema } from "../types";

const facilityPhotosResponseSchema = z.object({
  photosList: z.array(facilityPhotoSchema),
});

export type FacilityPhotosResponse = z.infer<typeof facilityPhotosResponseSchema>;

export interface FacilityPhotosParams {
  facilityId: string;
}

export function useFacilityPhotos(
  queryParams: FacilityPhotosParams,
  options: UseGetQueryOptions<FacilityPhotosResponse> = {}
) {
  const { facilityId } = queryParams;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/facilityprofile/photos/${facilityId}`,
    responseSchema: facilityPhotosResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_PHOTOS_FAILURE,
    },
    ...options,
  });
}
